<template>
  <div class="triggerListInfo mb-2 ml-3 mr-3 d-xl-block">
    <div
      v-if="triggers"
      class="row"
    >
      <TriggerListInfoItem
        class="col-6 mb-2 mb-xl-0"
        :priority="5"
        :triggers="triggers"
        :color="triggerPriorityMixin_priorityClass(5)"
      />
      <TriggerListInfoItem
        class="col-6 mb-2 mb-xl-0"
        :priority="4"
        :triggers="triggers"
        :color="triggerPriorityMixin_priorityClass(4)"
      />
      <TriggerListInfoItem
        class="col-6"
        :priority="3"
        :triggers="triggers"
        :color="triggerPriorityMixin_priorityClass(3)"
      />
      <TriggerListInfoItem
        class="col-6"
        :priority="2"
        :triggers="triggers"
        :color="triggerPriorityMixin_priorityClass(2)"
      />
    </div>
  </div>
</template>

<script>
import { triggerPriorityMixin } from '@/mixins/triggerPriorityMixin.js';
export default {
  name: 'TriggerListInfo',
  components: {
    TriggerListInfoItem: () => import('@/components/Problem/TriggerListInfoItem')
  },
  mixins: [
    triggerPriorityMixin,
  ],
  props: {
    triggers: {
      type: Array,
      required: false,
      default () {
        return null;
      }
    }
  }
}
</script>